var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.packages !== null)?_c('div',[_c('b-container',{staticClass:"bv-section1-row"},[_c('b-row',{staticClass:"px-3 px-lg-0"},[(_vm.localeTitle == 'en')?_c('h3',[_vm._v(" "+_vm._s(_vm.area.enTitle)+" ")]):_vm._e(),(_vm.localeTitle == 'cn')?_c('h3',[_vm._v(" "+_vm._s(_vm.area.cnTitle)+" ")]):_vm._e(),(_vm.localeTitle == 'hk')?_c('h3',[_vm._v(" "+_vm._s(_vm.area.hkTitle)+" ")]):_vm._e()]),(_vm.area.featuredVideoUrl)?_c('b-row',[(_vm.area.featuredVideoUrl)?_c('video',{staticClass:"w-100 px-3 px-lg-0",attrs:{"width":"1140","height":"640px","controls":"","autoplay":_vm.area.autoplay==='Y'?true:false,"loop":_vm.area.loopFlag==='Y'?true:false},domProps:{"muted":_vm.area.autoplay==='Y'?true:false}},[_c('source',{attrs:{"src":_vm.oss+_vm.area.featuredVideoUrl}}),_vm._v(" "+_vm._s(_vm.$t('header.browser_msg'))+" ")]):_vm._e()]):_vm._e()],1),(_vm.packages.length > 0)?_c('section',{staticClass:"secction-h tour-section-2 tour-section-4 mt-5 pt-5 pb-5"},[_c('carousel-3d',{attrs:{"controls-visible":true,"controls-prev-html":'&#10092; ',"controls-next-html":'&#10093;',"controls-width":30,"controls-height":60,"clickable":true,"width":405,"height":450}},[(_vm.packages[0])?_c('slide',{attrs:{"index":0}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[0].packageCode}`}},[_c('div',{staticClass:"d-flex flex-column-reverse",style:({
              backgroundImage: `url("${_vm.oss+_vm.packages[0].mainPicture}")`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '405px 450px',
              height: '100%',
            })},[_c('h5',{staticClass:"bp-title mx-2"},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":3}},[_vm._v(" ("+_vm._s(_vm.packages[0].shortDescription)+")")])],1),_c('p',{staticClass:"bp-title text-center"},[_vm._v(_vm._s(_vm.packages[0].title))])])])],1):_vm._e(),(_vm.packages[1])?_c('slide',{attrs:{"index":1}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[1].packageCode}`}},[_c('div',{staticClass:"d-flex flex-column-reverse",style:({
              backgroundImage: `url("${_vm.oss+_vm.packages[1].mainPicture}")`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '405px 450px',
              height: '100%',
            })},[_c('h5',{staticClass:"bp-title mx-2"},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":3}},[_vm._v(" ("+_vm._s(_vm.packages[1].shortDescription)+")")])],1),_c('p',{staticClass:"bp-title text-center"},[_vm._v(_vm._s(_vm.packages[1].title))])])])],1):_vm._e(),(_vm.packages[2])?_c('slide',{attrs:{"index":2}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[2].packageCode}`}},[_c('div',{staticClass:"d-flex flex-column-reverse",style:({
              backgroundImage: `url("${_vm.oss+_vm.packages[2].mainPicture}")`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '405px 450px',
              height: '100%',
            })},[_c('h5',{staticClass:"bp-title mx-2"},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":3}},[_vm._v(" ("+_vm._s(_vm.packages[2].shortDescription)+")")])],1),_c('p',{staticClass:"bp-title text-center"},[_vm._v(_vm._s(_vm.packages[2].title))])])])],1):_vm._e(),(_vm.packages[3])?_c('slide',{attrs:{"index":3}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[3].packageCode}`}},[_c('div',{staticClass:"d-flex flex-column-reverse",style:({
              backgroundImage: `url("${_vm.oss+_vm.packages[3].mainPicture}")`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '405px 450px',
              height: '100%',
            })},[_c('h5',{staticClass:"bp-title mx-2"},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":3}},[_vm._v(" ("+_vm._s(_vm.packages[3].shortDescription)+")")])],1),_c('p',{staticClass:"bp-title text-center"},[_vm._v(_vm._s(_vm.packages[3].title))])])])],1):_vm._e(),(_vm.packages[4])?_c('slide',{attrs:{"index":4}},[_c('router-link',{attrs:{"to":`/tour/${_vm.packages[4].packageCode}`}},[_c('div',{staticClass:"d-flex flex-column-reverse",style:({
              backgroundImage: `url("${_vm.oss+_vm.packages[4].mainPicture}")`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '405px 450px',
              height: '100%',
            })},[_c('h5',{directives:[{name:"shave",rawName:"v-shave",value:({
                height: 150,
                character: '...',
                spaces: false,
              }),expression:"{\n                height: 150,\n                character: '...',\n                spaces: false,\n              }"}],staticClass:"bp-title mx-2"},[_c('v-clamp',{attrs:{"autoresize":"","max-lines":3}},[_vm._v(" ("+_vm._s(_vm.packages[4].shortDescription)+")")])],1),_c('p',{staticClass:"bp-title text-center"},[_vm._v(_vm._s(_vm.packages[4].title))])])])],1):_vm._e()],1),_c('b-container',{staticClass:"bv-section4-row"},[(_vm.packages.length > 5)?_c('div',{staticClass:"text-center mt-2"},[_c('a',{attrs:{"href":`#/packages/section4`}},[_c('b-button',{staticClass:"view-more",attrs:{"squared":"","variant":"outline-secondary"}},[_vm._v(_vm._s(_vm.$t('More')))])],1)]):_vm._e()])],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }